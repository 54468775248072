import fetch from '../utils/fetch'

export function getPriceProtectList (query) {
  return fetch({
    url: '/insuranceapply',
    method: 'get',
    params: query
  })
}



export function getPriceProtectDetail (id) {
  return fetch({
    url: '/insuranceapply/' + id,
    method: 'get'
  })
}



export function checkPriceProtect (id, params) {
  return fetch({
    url: '/insuranceapply/' + id,
    method: 'put',
    params: params
  })
}
