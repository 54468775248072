import fetch from '../utils/fetch'

export function getApiLogsList (query) {
  return fetch({
    url: '/apiLogs',
    method: 'get',
    params: query
  })
}



export function getApiLogsDetail (id) {
  return fetch({
    url: '/apiLogs/' + id,
    method: 'get'
  })
}



export function retryApiLogs (params) {
  return fetch({
    url: '/apiLogs/retry',
    method: 'post',
    params: params
  })
}
